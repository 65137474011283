/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useState, useEffect, } from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';

import Header from './header';
import './layout.css';
import { CSSTransition } from 'react-transition-group';

const Layout = ({ children, mainClassName }) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  useEffect(() => {
    setIsLoaded(true);
  }, []);

  const descriptions = [
    'relax, i\'ve got no underpants on.',
    'how now brown cow',
    'spaghetti pockets',
    '安心してください。はいてませんよ',
    'やられたらやり返す 倍返しだ!',
  ];

    // transitionClassNames = 'header-transition';

  return (
    <>
      <CSSTransition in={isLoaded} timeout={5000} classNames="header-transition" appear>
        <Header
          siteTitle={data.site.siteMetadata.title}
          siteDescription={descriptions[Math.floor(Math.random() * descriptions.length)]}
        />
      </CSSTransition>
      <main className={mainClassName}>{children}</main>
      <footer>
        <p>
          © 2004-{new Date().getFullYear()} sayajay.net.
          powered by pizza and diet pop.
        </p>
      </footer>
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  mainClassName: PropTypes.string,
};
Layout.defaultProps = {
  mainClassName: '',
};

export default Layout;
