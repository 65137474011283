
import React, { useState, useEffect, } from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import cx from 'classnames';
import Navbar from './navbar';
import styles from './header.module.css';

const Header = ({ siteTitle, siteDescription }) => {
  const [theme, setTheme] = useState(undefined);

  useEffect(() => {
    setTheme(localStorage.getItem('theme'));
  }, []);

  const switchTheme = () => {
    if (theme === 'default' || !theme) {
      setTheme('dark');
      localStorage.setItem('theme', 'dark');
    } else if (theme === 'dark') {
      setTheme('default');
      localStorage.setItem('theme', 'default');
    }
  };
  return (
    <>
      <Helmet
        bodyAttributes={{
          class: `theme-${theme || 'default'}`,
        }}
      />
      <header className={cx(styles.main)}>
        <div className={cx(styles.body)}>
          <h1 style={{ marginBottom: 0, marginTop: 0 }}>{siteTitle}</h1>
          {/* <p style={{ margin: 0, fontSize: '1.2em' }}>{siteDescription}</p> */}
          <Navbar />
        </div>
        {/*<button className={styles.themeSwitch} onClick={switchTheme}>*/}
        {/*  {theme === 'dark' ? 'light' : 'dark'}*/}
        {/*  &nbsp;theme*/}
        {/*</button>*/}
      </header>
    </>
  );
};

Header.propTypes = {
  siteTitle: PropTypes.string,
  siteDescription: PropTypes.string,
};

Header.defaultProps = {
  siteTitle: '',
  siteDescription: ''
};

export default Header;
