
import React from 'react';
import { Link, } from 'gatsby';
import styles from './navbar.module.css';

const Navbar = () => (
  <ul className={styles.navbar}>
    <li>
      &middot;&nbsp;
      <Link to="/">home</Link>
    </li>
    {/*<li>*/}
    {/*  &middot;&nbsp;*/}
    {/*  <Link to="/">manga</Link>*/}
    {/*</li>*/}
    <li>
      &middot;&nbsp;
      <Link to="/category/japan">japan</Link>
    </li>
    <li>
      &middot;&nbsp;
      <Link to="/category/video-games">video games</Link>
    </li>
    <li>
      &middot;&nbsp;
      <Link to="/other">site map</Link>
    </li>
    <li>
      &middot;&nbsp;
      <Link to="/404">about</Link>
    </li>
  </ul>
);

export default Navbar;
